import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/_metronic/shared/api/rest-api.service';
import { Constant } from 'src/app/_metronic/shared/api/constant';
import { AdminModel } from '../_models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  // private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private authLocalStorageToken = 'admin';
  // public fields
  // currentUser$: Observable<UserModel>;
  currentUser$: Observable<AdminModel>;
  isLoading$: Observable<boolean>;
  // currentUserSubject: BehaviorSubject<UserModel>;
  currentUserSubject: BehaviorSubject<AdminModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  res: any;


  // get currentUserValue(): UserModel {
  //   return this.currentUserSubject.value;
  // }

  // set currentUserValue(user: UserModel) {
  //   this.currentUserSubject.next(user);
  // }

  get currentUserValue(): AdminModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: AdminModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private restApiService: RestApiService,
    public constant: Constant
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    // this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUserSubject = new BehaviorSubject<AdminModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    
    this.isLoadingSubject.next(true);
    let req = {
      "email": email,
      "password": password
    }
      return this.restApiService.postRequest(this.constant.login,req).pipe(
      map((auth: any) => {
         
        this.res = auth
        if(this.res){
          if( this.res.success === false){
            return false;
          }
            const result = this.setAuthFromLocalStorage(auth)//this.getUserByToken();;
            return result;
            
            // 
        }
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // login(email: string, password: string): Observable<UserModel> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.login(email, password).pipe(
  //     map((auth: AuthModel) => {
  //       const result = this.setAuthFromLocalStorage(auth);
  //       return result;
  //     }),
  //     switchMap(() => this.getUserByToken()),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  // getUserByToken(): Observable<UserModel> {
  //   const auth = this.getAuthFromLocalStorage();
  //   if (!auth || !auth.accessToken) {
  //     return of(undefined);
  //   }
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.getUserByToken(auth.accessToken).pipe(
  //     map((user: UserModel) => {
  //       if (user) {
  //         this.currentUserSubject = new BehaviorSubject<UserModel>(user);
  //       } else {
  //         this.logout();
  //       }
  //       return user;
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  getUserByToken(): Observable<AdminModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserById(this.constant.user,auth.id).pipe(
      map((user: any) => {
        
        if (user) {
          this.currentUserSubject = new BehaviorSubject<AdminModel>(user.data);
        } else {
          this.logout();
        }
        
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  // registration(user: UserModel): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.createUser(user).pipe(
  //     map(() => {
  //       this.isLoadingSubject.next(false);
  //     }),
  //     switchMap(() => this.login(user.email, user.password)),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  registration(user: UserModel): Observable<any> {
    
    this.isLoadingSubject.next(true);
    let req = {
      "email": user.email,
      "name": user.fullname,
      "password": user.password
    }
  
  return this.restApiService.postRequest(this.constant.signup,req)
  // .pipe(
  // map((response: any) => {
  // response

  // }),
  // // switchMap(() => this.getUserByToken()),
  // catchError((err) => {
  // console.error('err', err);
  // return of(undefined);
  // }),
  // finalize(() => this.isLoadingSubject.next(false))
  // );
  }

  // forgotPassword(email: string): Observable<boolean> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService
  //     .forgotPassword(email)
  //     .pipe(finalize(() => this.isLoadingSubject.next(false)));
  // }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    let requestBody = {'email':email}
    return this.restApiService.postRequest(this.constant.forgot_password, requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  otpCheck(requestBody: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.restApiService.postRequest(this.constant.check_otp, requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  newPassword(requestBody: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.restApiService.postRequest(this.constant.new_password, requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(requestBody: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.restApiService.postRequest(this.constant.change_password, requestBody)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  
  
  // private methods
  // private setAuthFromLocalStorage(auth: AuthModel): boolean {
  //   // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
  //   if (auth && auth.accessToken) {
  //     localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
  //     return true;
  //   }
  //   return false;
  // }

  public setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.data.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth.data));
      // let user = new AdminModel()
      // user.id = auth.id
      // user.name = auth.name
      //   this.currentUserSubject = new BehaviorSubject<AdminModel>(user);
      return true;
    }
    return false;
  }

  // private getAuthFromLocalStorage(): AuthModel {
  //   try {
  //     const authData = JSON.parse(
  //       localStorage.getItem(this.authLocalStorageToken)
  //     );
  //     return authData;
  //   } catch (error) {
  //     console.error(error);
  //     return undefined;
  //   }
  // }

  private getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
